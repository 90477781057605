<template>
  <div>
    <Header
      :title="$t('workflowDesigner.workflowCreate')"
      :show-back-button="true"
      :items="headerItems"
      @back="$router.push({ name: 'projectWorkflows' })"
      @save="submit"
    />
    <div
      v-if="queues.length <= 0"
      class="mb-3"
      @click="$router.push({ name: 'projectSettingsQueuesCreate' })"
    >
      <div class="m-0 alert alert-custom alert alert-warning alert-link">
        <div class="alert-icon">
          <i class="fal fa-triangle-exclamation"></i>
        </div>
        <div class="alert-text">
          {{ $t("workflowDesigner.createNewQueue") }}
        </div>
      </div>
    </div>
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="process"
          :form="form"
          :config="config"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProcessManager from "@/components/Workflows/processManager";
import Workflows from "@/components/Workflows/Designer/workflows";
import Header from "@/components/Tools/Header/Header.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import { mapGetters } from "vuex";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import Queues from "@/components/Settings/Queues/queues";
import { useCurrentProjectFilter } from "@/composables/useCurrentProjectFilter";

export default {
  components: {
    FormHelper,
    Header
  },
  props: [],
  data() {
    return {
      headerItems: [
        {
          type: "button",
          title: this.$t("general.save"),
          emit: "save"
        }
      ],
      config: {
        snippetPrefix: "process",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("workflowDesigner.name"),
          name: "name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("workflowDesigner.description"),
          name: "description",
          type: "textarea",
          default: "",
          validations: {
            required: false
          }
        },
        {
          label: this.$t("workflowDesigner.active"),
          name: "active",
          type: "select",
          default: "active",
          options: [
            {
              label: this.$t("workflowDesigner.active"),
              value: "active"
            },
            {
              label: this.$t("workflowDesigner.inactive"),
              value: "inactive"
            },
            {
              label: this.$t("workflowDesigner.advanced"),
              value: "advanced"
            }
          ]
        },
        {
          type: "condition",
          name: "active_condition",
          enableVariables: true,
          label: this.$t("workflowDesigner.activeCondition"),
          dependsOn: [
            {
              name: "active",
              values: ["advanced"]
            }
          ]
        },
        {
          label: this.$t("workflowDesigner.projectChoose"),
          name: "projects",
          type: "multiselect",
          clearable: true,
          options: [],
          validations: {
            required: true
          }
        },
        {
          label: this.$t("workflowDesigner.queueChoose"),
          name: "queue_id",
          type: "select",
          options: [],
          validations: {
            required: true
          }
        },
        {
          label: this.$t("workflowDesigner.setParameters"),
          name: "parameters",
          type: "group",
          fields: [
            {
              name: "text",
              label: this.$t("processConfiguration.name"),
              type: "text"
            },
            {
              name: "type",
              label: this.$t("processConfiguration.type"),
              type: "select",
              options: ["string", "integer", "float", "boolean"]
            },
            {
              name: "value",
              label: this.$t("processConfiguration.defaultValue"),
              type: "text"
            }
          ],
          validations: {
            required: false
          }
        }
      ],
      process: {
        name: "",
        description: "",
        projects: [],
        queue_id: "",
        managed_by_process_engine: false,
        active_condition: {
          type: "group",
          operator: "and",
          children: [
            {
              type: "condition",
              field: "",
              operator: "==",
              value: "",
              valid: true
            }
          ],
          valid: true
        }
      },
      workflows: [],
      queues: ["placeholder"]
    };
  },
  computed: {
    ...mapGetters(["projects", "selectedProject"]),
    ...mapGetters("instance", ["instance"])
  },
  watch: {
    selectedProject: function () {
      this.loadQueues();
      this.setProjects();
    }
  },
  mounted() {
    if (this.instance.xentralUrl) {
      this.form.push({
        label: this.$t("workflowDesigner.managedByProcessEngine"),
        name: "managed_by_process_engine",
        type: "checkbox",
        default: false
      });
    }

    this.loadQueues();
    this.setProjects();
  },
  methods: {
    setProjects() {
      // Fills multiselect projects
      const formProjects = Object.values(this.form).find(
        element => element.name === "projects"
      );
      formProjects.options = this.getProjectOptions();
      formProjects.default = this.getCurrentProject();
    },
    setQueues() {
      const formQueues = Object.values(this.form).find(
        element => element.name === "queue_id"
      );
      formQueues.options = this.getQueues();
    },
    loadQueues() {
      const params = {
        page: 1,
        size: 100
      };
      addEventToLoadingQueue({ key: "loadQueues" });
      const { filter } = useCurrentProjectFilter();

      Queues.getAll(params, filter.value)
        .then(response => {
          this.queues = response.data;
          this.setQueues();
          removeEventFromLoadingQueue({ key: "loadQueues" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    getWorkflows() {
      return this.workflows.map(workflow => ({
        label: workflow.label,
        value: workflow.process_id
      }));
    },
    getProjectOptions() {
      return this.projects.map(project => ({
        label: project.name,
        value: project.id
      }));
    },
    getCurrentProject() {
      this.process.projects = [this.selectedProject.id];
      return {
        label: this.selectedProject.name,
        value: this.selectedProject.id
      };
    },
    getQueues() {
      if (this.queues.length <= 0) {
        return [];
      }
      this.process.queue_id = this.queues[0].id;
      return this.queues.map(queue => ({
        label: queue.raw_name,
        value: queue.id
      }));
    },
    submit() {
      this.storeProcess();
    },
    storeProcess() {
      addEventToLoadingQueue({ key: "storeProcess" });

      let process = this.process;
      process.updated_by_user_id = this.$store.getters.userData.id;

      ProcessManager.store(process)
        .then(response => {
          const storedProcess = response.data;
          removeEventFromLoadingQueue({ key: "storeProcess" });
          this.updateWorkflow(storedProcess);
        })
        .catch(error => {
          this.$error(error);
        });
    },
    updateWorkflow(storedProcess) {
      addEventToLoadingQueue({ key: "updateWorkflow" });

      let process = this.process;
      process.updated_by_user_id = this.$store.getters.userData.id;

      Workflows.update(storedProcess.workflow_id, process)
        .then(() => {
          removeEventFromLoadingQueue({ key: "updateWorkflow" });
          this.$router.push({
            name: "projectWorkflowsEditor",
            params: { id: storedProcess.id }
          });
        })
        .catch(error => {
          this.$error(error);
        });
    }
  }
};
</script>

<style lang="scss">
.alert {
  background-color: #ffffff;
}
</style>
