<template>
  <div>
    <Header
      :title="$t('workflowDesigner.edit') + ' ' + process.name"
      :subtitle="subtitle"
      :show-back-button="true"
      :items="headerItems"
      @back="$router.push({ name: 'projectWorkflows' })"
      @save="saveData"
      @showVersions="onShowVersions"
    />
    <div class="card card-custom">
      <div class="card-body">
        <FormHelper
          ref="form"
          v-model="process"
          :form="form"
          :config="config"
        />
      </div>
    </div>
    <BackupModal
      v-if="process && process.workflow"
      v-model="showVersionsModal"
      :object="process.workflow"
      classname="Workflow"
    />
  </div>
</template>

<script>
import Workflows from "@/components/Workflows/Designer/workflows";
import ProcessManager from "@/components/Workflows/processManager";
import Header from "@/components/Tools/Header/Header.vue";
import FormHelper from "@/components/Tools/FormHelper/FormHelper.vue";
import {
  addEventToLoadingQueue,
  removeEventFromLoadingQueue
} from "@/composables/useLoadingQueue";
import FlowTableEntries from "@/components/Workflows/Designer/Components/flowTableEntries";
import Queues from "@/components/Settings/Queues/queues";
import { mapGetters } from "vuex";
import { formatDate } from "@/components/Tools/helperFunctions";
import BackupModal from "@/components/Backup/BackupModal.vue";
import { useCurrentProjectFilter } from "@/composables/useCurrentProjectFilter";
import { useWorkNote } from "@/composables/useWorkNote";

export default {
  components: {
    BackupModal,
    FormHelper,
    Header
  },
  data() {
    return {
      config: {
        snippetPrefix: "process",
        enableVariables: false
      },
      form: [
        {
          label: this.$t("workflowDesigner.name"),
          name: "name",
          type: "text",
          disableReturnType: true,
          default: "",
          validations: {
            required: true,
            minLength: 3
          }
        },
        {
          label: this.$t("workflowDesigner.description"),
          name: "description",
          type: "textarea",
          default: "",
          validations: {
            required: false
          }
        },
        {
          label: this.$t("workflowDesigner.active"),
          name: "active",
          type: "select",
          default: "active",
          options: [
            {
              label: this.$t("workflowDesigner.active"),
              value: "active"
            },
            {
              label: this.$t("workflowDesigner.inactive"),
              value: "inactive"
            },
            {
              label: this.$t("workflowDesigner.advanced"),
              value: "advanced"
            }
          ]
        },
        {
          type: "condition",
          name: "active_condition",
          enableVariables: true,
          label: this.$t("workflowDesigner.activeCondition"),
          dependsOn: [
            {
              name: "active",
              values: ["advanced"]
            }
          ]
        },
        {
          label: this.$t("workflowDesigner.projectChoose"),
          name: "projects",
          type: "multiselect",
          clearable: true,
          options: [],
          validations: {
            required: true
          }
        },
        {
          label: this.$t("workflowDesigner.queueChoose"),
          name: "queue_id",
          type: "select",
          options: [],
          validations: {
            required: true
          }
        },
        {
          label: this.$t("workflowDesigner.setParameters"),
          name: "parameters",
          type: "group",
          fields: [
            {
              name: "text",
              label: this.$t("processConfiguration.name"),
              type: "text"
            },
            {
              name: "type",
              label: this.$t("processConfiguration.type"),
              type: "select",
              options: ["boolean", "integer", "float", "string"]
            },
            {
              name: "value",
              label: this.$t("processConfiguration.defaultValue"),
              type: "text"
            }
          ],
          validations: {
            required: false
          }
        }
      ],
      process: {
        id: "",
        name: "",
        description: "",
        projects: [],
        queue_id: "",
        managed_by_process_engine: false,
        workflow: {},
        active_condition: {
          type: "group",
          operator: "and",
          children: [
            {
              type: "condition",
              field: "",
              operator: "==",
              value: "",
              valid: true
            }
          ],
          valid: true
        }
      },
      workflows: [],
      showVersionsModal: false
    };
  },
  computed: {
    ...mapGetters([
      "projects",
      "selectedProject",
      "selectedPresetVersion",
      "isPresetProject",
      "isDevPresetVersion"
    ]),
    ...mapGetters("instance", ["instance"]),
    headerItems: function () {
      let headerItems = [
        {
          type: "button",
          icon: this.$store.getters["config/icons"].version,
          tooltip: this.$t("general.showVersions"),
          class: "btn-outline-primary",
          emit: "showVersions",
          disabledWhenLoading: true
        }
      ];
      if (this.isPresetProject || !this.isDevPresetVersion) {
        return headerItems;
      }
      headerItems.push({
        type: "button",
        title: this.$t("general.save"),
        emit: "save",
        disabledWhenLoading: true
      });
      return headerItems;
    },
    subtitle: function () {
      if (!this.process.workflow) {
        return "";
      }

      let subtitleParts = [];
      if (this.process.workflow.updated_by_user?.full_name) {
        subtitleParts.push(this.process.workflow.updated_by_user?.full_name);
      }
      if (this.process.workflow.updated_at) {
        subtitleParts.push(formatDate(this.process.workflow.updated_at));
      }
      if (subtitleParts.length === 0) {
        return "";
      }

      return subtitleParts.join(", ");
    }
  },
  watch: {
    projects: function () {
      this.setProjects();
    },
    selectedPresetVersion: function () {
      this.initialize();
    }
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.instance.xentralUrl) {
        this.form.push({
          label: this.$t("workflowDesigner.managedByProcessEngine"),
          name: "managed_by_process_engine",
          type: "checkbox",
          default: false
        });
      }

      this.loadWorkflow();
      this.setProjects();
      this.loadWorkflows();
      this.loadQueues();
    },
    loadWorkflow() {
      addEventToLoadingQueue({ key: "loadWorkflow" });
      let id = this.$route.params.id;

      ProcessManager.get(id)
        .then(response => {
          let process = response.data;

          this.process = process;
          this.process.description =
            process.workflow.description !== "undefined"
              ? process.workflow.description
              : "";
          this.process.projects = process.workflow.projects.map(
            project => project.id
          );
          this.process.queue_id = process.queue?.id;
          this.process.workflow.id = process.workflow.id;

          removeEventFromLoadingQueue({ key: "loadWorkflow" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async saveData() {
      let process = this.process;
      process.updated_by_user_id = this.$store.getters.userData.id;

      const { addWorkNote } = useWorkNote();
      const { data, success } = await addWorkNote(process);

      if (!success) {
        return;
      }

      // remove tokens to avoid overwriting them with a Model->sync
      // delete data["flow_tokens"];
      delete data["flow_tokens"];
      addEventToLoadingQueue({ key: "saveData" });
      ProcessManager.update(process.id, data)
        .then(() => {
          removeEventFromLoadingQueue({ key: "saveData" });
          this.saveWorkflow();
        })
        .catch(error => {
          this.$error(error);
        });
    },
    saveWorkflow() {
      addEventToLoadingQueue({ key: "saveWorkflow" });
      Workflows.update(this.process.workflow.id, this.process)
        .then(() => {
          removeEventFromLoadingQueue({
            key: "saveWorkflow",
            type: "success",
            prefix: "workflowDesigner",
            name: "workflowSaved"
          });

          this.$router.push({ name: "projectWorkflows" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    setProjects() {
      // Fills multiselect projects
      const formProjects = Object.values(this.form).find(
        element => element.name === "projects"
      );
      formProjects.options = this.getProjectOptions();
    },
    setQueues() {
      const formQueues = Object.values(this.form).find(
        element => element.name === "queue_id"
      );
      formQueues.options = this.getQueues();
    },
    loadWorkflows() {
      addEventToLoadingQueue({ key: "loadWorkflows" });
      // Load more than 15 workflows
      let params = {
        perPage: 100
      };
      const { filter } = useCurrentProjectFilter();

      FlowTableEntries.getAll(params, filter.value)
        .then(response => {
          this.workflows = response.data;
          removeEventFromLoadingQueue({ key: "loadWorkflows" });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    loadQueues() {
      const params = {
        page: 1,
        perPage: 100
      };
      const { filter } = useCurrentProjectFilter();

      Queues.getAll(params, filter.value)
        .then(response => {
          this.queues = response.data;
          this.setQueues();
        })
        .catch(error => {
          this.$error(error);
        });
    },
    getWorkflows() {
      return this.workflows.map(workflow => ({
        label: workflow.label,
        value: workflow.process_id
      }));
    },
    getProjectOptions() {
      return this.projects.map(project => ({
        label: project.name,
        value: project.id
      }));
    },
    getCurrentProject() {
      return {
        label: this.selectedProject.name,
        value: this.selectedProject.id
      };
    },
    getQueues() {
      return this.queues.map(queue => ({
        label: queue.raw_name,
        value: queue.id
      }));
    },
    onShowVersions() {
      this.showVersionsModal = true;
    }
  }
};
</script>
